<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script>
export default {
    name: "SectionToolbar",
    data() {
        return {
            baseClasses: "w-full px-6 py-4 grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-y-6 sm:gap-6 sm:items-center justify-start",
        };
    },
    computed: {
        classes() {
            return `section-toolbar ${this.baseClasses}`;
        },
    },
};
</script>
